<template>
  <div>

    <!-- Filters -->
    <!-- <historico-list-filters
      :situacao-filter.sync="situacaoFilter"
      :situacao-options="situacaoOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-size-sm"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Procurar..."
                debounce="600"
              />
           
              <b-button
                variant="outline-success"
                class="ml-1"
                v-b-modal.modal-export
              >
                <span class="text-nowrap">Exportar Planilha</span>
              </b-button>

            </div>
          </b-col>

          <b-modal
            id="modal-export"
            cancel-variant="outline-secondary"
            ok-variant="primary"
            ok-title="Confirmar"
            cancel-title="Cancelar"
            centered
            title="Confirmar a exportação de relatório em excel"
            @ok="exportarRelatorio"
          >            
          </b-modal>
        </b-row>
      </div>

      <b-table
        ref="refHistoricoListTable"
        class="position-relative"
        responsive
        striped
        hover
        :items="fetchHistoricos"
        :fields="tableColumns"  
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >

        <!-- Column: Status -->
        <template #cell(situacao_cobranca)="data">
          <b-badge
            pill
            :variant="`light-${resolveCobrancaStatusVariant(data.item.situacao_cobranca)}`"
            class="text-capitalize"
          >
            {{ data.item.situacao_cobranca }}
          </b-badge>
        </template>

        <template #cell(situacao_titulo)="data">
          <b-badge
            pill
            :variant="`light-${resolveTituloStatusVariant(data.item.situacao_titulo)}`"
            class="text-capitalize"
          >
            {{ data.item.situacao_titulo }}
           
          </b-badge>
        </template>

        <!-- Column: Credor -->
        <template #cell(credor)="data">
          <span class="font-weight-bolder">{{ data.item.credor }}</span>
        </template>

      </b-table>

      <!-- METADATA -->
      <div class="mx-2 mb-2">
      <b-row>

          <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <span class="text-muted">Exibindo de {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.total }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

          <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
              <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
              </template>
              <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
              </template>
          </b-pagination>

          </b-col>

      </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
// import HistoricoListFilters from './HistoricoListFilters.vue'
import useHistoricoList from './useHistoricoList'
import historicoStoreModule from './historicoStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {
    // HistoricoListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,

    vSelect,
  },
  setup() {
    const HISTORICO_APP_STORE_MODULE_NAME = 'app-historico'

    // Register module
    if (!store.hasModule(HISTORICO_APP_STORE_MODULE_NAME)) store.registerModule(HISTORICO_APP_STORE_MODULE_NAME, historicoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HISTORICO_APP_STORE_MODULE_NAME)) store.unregisterModule(HISTORICO_APP_STORE_MODULE_NAME)
    })

    const situacaoOptions = [
      { label: 'Ativo', value: 'ATIVO' },
      { label: 'Inativo', value: 'INATIVO' }
    ]

    function exportarRelatorio() {
      axios.post('exportar/historico')
      .then((response) => {        
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Exportação relatório',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Seu relatório será gerado!`,
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Exportação de relatório',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: `Não foi possível gerar seu relatório ):`,
          },
        })
      });
    }

    const {
      fetchHistoricos,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refHistoricoListTable,
      refetchData,

      // UI
      resolveCobrancaStatusVariant,
      resolveTituloStatusVariant,

      // Extra Filters
      situacaoFilter
    } = useHistoricoList()

    return {

      fetchHistoricos,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refHistoricoListTable,
      refetchData,

      // UI
      resolveCobrancaStatusVariant,
      resolveTituloStatusVariant,

      // Extra Filters
      situacaoOptions,
      situacaoFilter,
      exportarRelatorio
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
