import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useHistoricoList() {
  // Use toast
  const toast = useToast()

  const refHistoricoListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'cliente' },
    { key: 'credor' },
    { key: 'situacao_titulo' },
    { key: 'canal' },
    { key: 'destinatario' },
    { key: 'situacao_cobranca' },
    { key: 'data_hora' },
    { key: 'usuario' },
    // { key: 'actions', label: "Ações" },
  ]
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const situacaoFilter = ref(null)

  const dataMeta = ref({})

  const refetchData = () => {
    refHistoricoListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situacaoFilter], () => {
    refetchData()
  })

  const fetchHistoricos = (ctx, callback) => {
    store
      .dispatch('app-historico/fetchHistoricos', {
        busca: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        // situacao: situacaoFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching historico list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCobrancaStatusVariant = status => {
    if (status === 'SUCESSO') return 'success'
    if (status === 'PENDENTE') return 'warning'
    if (status === 'FALHA') return 'danger'
    return 'primary'
  }
  const resolveTituloStatusVariant = status => {
    if (status === 'NEGATIVADO') return 'primary'
    if (status === 'BAIXADO') return 'success'
    if (status === 'PRESCRITO') return 'danger'
    return 'primary'
  }


  return {
    fetchHistoricos,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refHistoricoListTable,

    resolveCobrancaStatusVariant,
    resolveTituloStatusVariant,
    refetchData,

    // Extra Filters
    situacaoFilter
  }
}
